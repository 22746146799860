<template>
  <div class="mb-4">
      <CRow class="mb-0">
        <CCol lg="2">
          <CInput v-model="part_no" placeholder="Part No."/>
        </CCol>
        <CCol lg="2">
          <CInput v-model="part_identifier" placeholder="Part Identifier"/>
        </CCol>
        <CCol lg="2">
          <CInput v-model="part_name" placeholder="Parts Name"/>
        </CCol>
        <CCol lg="2">
          <v-select 
            label="setting_name" 
            :options="partsCategoryList.data"
            :reduce="item => item.id"
            v-model="category_id"
            placeholder="Category"
          > 
          </v-select>  
        </CCol>
        <!-- <CCol lg="3">
          <v-select 
            label="setting_name" 
            :options="vehicleTypeList.data"
            :reduce="item => item.id"
            v-model="vehicle_type_id"
            placeholder="Type"
          > 
          </v-select> 
        </CCol>  -->
        <CCol lg="2">
        </CCol>
        <CCol lg="2" class="float-right">
          <CButton size="sm" block color="info" @click="search"><i class="icon-magnifier"></i> Search</CButton>
        </CCol>
      </CRow>
      <CRow>
        <CCol lg="3">
          <!-- <v-select 
            label="setting_name" 
            :options="partsCategoryList.data"
            :reduce="item => item.id"
            v-model="category_id"
            placeholder="Category"
          > 
          </v-select>   -->
        </CCol>
        <CCol lg="3">
          <!-- <CInput size="sm" v-model="manufacturer" placeholder="Manufacturer"/> -->
        </CCol>
        <CCol lg="3"> 
        </CCol>
        <CCol lg="1">
        </CCol>
        <CCol lg="2" class="float-right">
          <CButton size="sm" block @click="clearSearch" color="info">Clear Search</CButton>
        </CCol>
      </CRow> 
  </div>
</template>

<script>
  import config from '../config.js';
  import axios from '../axios';
  import vSelect from 'vue-select'
  import Datepicker from 'vuejs-datepicker';
  import 'vue-select/dist/vue-select.css';
    export default {
        name: "BookingTypeSearch",
        data() {
          	return {
	            part_no: "",
	            part_identifier: "",
	            part_name: "",
	            category_id: "",
	            manufacturer: "",  
				partsCategoryList: {
					data: []
				},
          	}
        },
      mounted() {
        this.getPartsCategory();
      },
      components: {vSelect, Datepicker},
      methods: {
        search() {
          const data = {
            part_no: this.part_no,
            part_identifier: this.part_identifier,
            part_name: this.part_name,
            category_id: this.category_id,
            manufacturer: this.manufacturer,  
          }
          this.$emit("depot-search-query", data)
        },
        clearSearch() {
          this.part_no = "";
          this.part_identifier = "";
          this.part_name = "";
          this.category_id = "";
          this.manufacturer = "";   
          const data = {
            part_no: this.part_no,
            part_identifier: this.part_identifier,
            part_name: this.part_name,
            category_id: this.category_id,
            manufacturer: this.manufacturer,  
          }
          this.$emit("depot-search-query", data)
        },

        getPartsCategory(){

	    	axios.get(config.api_path+'/setting?setting_type=part_category&page=1&limit=10000000')
	    	.then(response => {
	    		this.partsCategoryList = response.data; 
	    	})

	    },
      }
    }
</script>

<style scoped>

</style>
