<template>
	<div>
		<CCard class="p-4">
		<CRow>
			
			<CCol lg="6">
					<h5> Parts Inventory ({{count}})</h5>
			</CCol>
	     		 <CCol lg="6">
					   <CButton size="sm" style="float: right;" :class="'float-lg-right block '+showCollapse ? 'collapsed' : null"
			                 :aria-expanded="showCollapse ? 'true' : 'false'"
			                 aria-controls="collapse1"
			                 @click="showCollapse = !showCollapse"
			                 color="info"><i class="fa fa-search"></i>Filter<i :class="showCollapse ? 'fa fa-caret-up' : 'fa fa-caret-down'"></i></CButton>
			       <CButton 
							v-if="config.getPermission('part_inventory').create"
							size="sm" style="margin-right: 10px" 
							color="success" 
							href="#/main/create_parts_inventory" target="_blank"
							class="float-lg-right">
							<i class="fa fa-plus">
							</i>Add Parts Entry
					</CButton>

		      		
			    </CCol>
		</CRow>
		<CRow> 
				<CCol lg="12">
					<CCollapse id="collapse1" :show="showCollapse" class="mt-2">
				        <CCard class="p-4">
				          <Search @depot-search-query="search"/>
				        </CCard>
				      </CCollapse> 
				</CCol>
		</CRow> 
		<hr>

		<CRow>			
	      
		      <CCol lg="12">  
				  <div class="table-responsive-sm table-responsive-md">
					<CTabs variant="pills" :active-tab="0">
				    <CTab title="All">
				    	<br/>
						<!-- <CCol lg="12" class="text-center" v-if="isLoading"> 
						  <div class="spinner-border m-5" role="status">
							<span class="sr-only">Loading...</span>
						</div>
					   </CCol> -->
				      	<CDataTable
						  :loading="isLoading"
					      	:items="dataListAll.data"
					      	:fields="fields"
							hover
							striped
							outlined
							
					    >  
					    	
					      	<template #action="{item}">
						        <td style="padding: 5px; width: 100px">
						        	<CDropdown 
						                toggler-text="Select"  
										size="sm"
						                color="info"
						            >	
						            	<CDropdownItem v-if="config.getPermission('part_inventory').view"  @click="viewPartsInventory(item)"><i class="fa fa-eye">View </i></CDropdownItem>
						                <CDropdownItem v-if="config.getPermission('part_inventory').update" @click="updatePartsInventory(item)"><i class="fa fa-pencil-square-o"> Edit</i></CDropdownItem>
										<CDropdownItem v-if="config.getPermission('part_inventory').update" @click="updateModal(item)"><i class="fa fa-paper-plane"> Update Qty</i></CDropdownItem>
						                <CDropdownItem v-if="config.getPermission('part_inventory').delete"  @click="deleteRow(item)"><i class="fa fa-trash"> Delete </i></CDropdownItem> 
						            </CDropdown>
						        </td>
					      	</template>
					    </CDataTable> 
					    <CPagination
				          	:active-page.sync="currentPageAll"
				          	:pages="Math.ceil(dataListAll.total / 20)"
				          	:activePage="currentPageAll"
				          	@update:activePage="updatePageAll"
				        />
				    </CTab>
	      		
				    <CTab title="Replenishment">
				    	<br/>
						
				      	<CDataTable
						  :loading="isLoading"
					      	:items="dataListAllReplineshment.data"
					      	:fields="fields"
							hover
							striped
							outlined
							
							
					    >  
					      	<template #action="{item}">
						        <td style="padding: 5px; width: 100px">
						        	<CDropdown 
						                toggler-text="Select"  
										size="sm"
						                color="info"
						            >
									<CDropdownItem v-if="config.getPermission('part_inventory').view"  @click="viewPartsInventory(item)"><i class="fa fa-eye">View </i></CDropdownItem>
						                <CDropdownItem v-if="config.getPermission('part_inventory').update" @click="updatePartsInventory(item)"><i class="fa fa-pencil-square-o"> Edit</i></CDropdownItem>
										<CDropdownItem v-if="config.getPermission('part_inventory').update" @click="updateModal(item)"><i class="fa fa-paper-plane"> Update Qty</i></CDropdownItem>
						                <CDropdownItem v-if="config.getPermission('part_inventory').delete"  @click="deleteRow(item)"><i class="fa fa-trash"> Delete </i></CDropdownItem> 
						            </CDropdown>
						        </td>
					      	</template>
					    </CDataTable> 
					    <CPagination
				          	:active-page.sync="currentPageReplineshment"
				          	:pages="Math.ceil(dataListAllReplineshment.total / 20)"
				          	:activePage="currentPageReplineshment"
				          	@update:activePage="updatePageReplineshment"
				        />
				    </CTab>

				</CTabs> 
				   </div>
	      	</CCol>
			  
	    </CRow>
	   
		 <CModal  :title="title" :show.sync="formModal" color="info" size="md">
			
           	<form @submit.prevent="submit"> 
				<CCard class="p-4">
				<CRow class="mb-2">
	        		<CCol lg="6">
							Part No. : <strong>{{dataParams.part_no}}</strong>
					</CCol>
					
					<CCol lg="12">
							Part Name : <strong>{{dataParams.part_name}}</strong>
					</CCol>
				</CRow>
				<hr class="mt-0">
				<CRow>
		
					<CCol lg="12">
						<label class="mr-2">Update Qty <span class="text-danger">*</span> </label>
						<CInput
		                  required
		                  placeholder="Enter qty"
		                  v-model="dataParams.current_qty" 
		                  type="text"
		                />
					</CCol>			
				</CRow>
				<CRow>
					<CCol lg="12">
						<CInput
		                  label="Current Qty"
		                  v-model="dataParams.main_qty" 
		                  type="text"
						  disabled
		                />
					</CCol> 

				</CRow>
				
				<CRow>
					<CCol lg="12">
							<div class="form-group"> 
									<label class="mr-2">Reasons <span class="text-danger">*</span> </label>
									<v-select 
										label="setting_name" 
										:options="[
											{
												id: 'broken',
												setting_name: 'Broken'
											},
											{
												id: 'checked inventory',
												setting_name: 'Checked Inventory'
											},
											{
												id: 'expired',
												setting_name: 'Expired'
											},
											{
												id: 'lost',
												setting_name: 'Lost'
											},
											{
												id: 'found',
												setting_name: 'Found'
											},
											{
												id: 'stolen',
												setting_name: 'Stolen'
											},
											{
												id: 'transfer',
												setting_name: 'Transfer'
											},
											{
												id: 'sold',
												setting_name: 'Sold'
											}
											
										]"
										:reduce="item => item.id"
										v-model="dataParams.reason"
										placeholder="Select reason" 
									>
										<template #search="{attributes, events}">
											<input
												class="vs__search"
												:required="!dataParams.reason"
												v-bind="attributes"
												v-on="events"
											/>
										</template>
									</v-select>
								</div>
							</CCol> 
				</CRow>
		</CCard>
				
	            <div slot="footer" class="w-100">
					<CButton size="sm" type="submit" style="border-radius: .2rem; color: white;" color="info" class="ml-1 mr-1 float-right">
	                    Save
	                </CButton>
	            	<CButton size="sm" style="border-radius: .2rem; color: white;" color="dark" class="ml-1 mr-1 float-right" @click="formModal = false">
	                    Cancel
	                </CButton>
	               
	                
	            </div>
          	</form>
           	<div slot="footer" class="w-100"></div>
        </CModal>
		</CCard>
	</div>
</template>
<script> 
import config from '../config.js';
import axios from '../axios';
import Swal from 'sweetalert2';
import Search from './search'
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import moment from 'moment';

export default {
	mounted(){          
		// this.getDataEquipment();
		// this.getDataVehicle();
		this.getDataAll();
		this.getDataAllReplineshment();
		for(var i = 0; i < document.querySelectorAll('.nav-item').length; i++){
			document.querySelectorAll('.nav-item')[i].onclick = ()=>{
				this.current_tab = document.querySelector(".nav-link.active").innerHTML;
				this.setCount();
			}
		}
	},
	data(){
		return{
			current_tab: "All",
			count: 0,
			title:'',
			isLoading: false,
			config,
			formModal: false,
			showViewModal: false,
			dataListAllEquipment:[],
			dataList: [],
			equipmentCurrentLocationList:[], 
			dataListAll: {
				data:[]
			},
			dataListAllReplineshment: {
				data:[]
			},
			dataListVehicle: {
				data:[]
			},
			dataListEquipment: {
				data:[]
			},
			depotName: "", 
			dataParams: {
				vehicle_id: "",
				equipment_id: "",
				asset_no: "",
				current_location_id: "",
				type: "",
				incident_date: "",
				report_by: "",
				description: "",
				remarks: "",
				class_type: "vehicle"
			},
			fields: [
				{
					key: 'part_no', 
					label: 'Part No.'
				},
				{
					key: 'part_identifier', 
					label: 'Part Identifier'
				},
				{
					key: 'part_name', 
					label: 'Parts Name'
				},
				{
					key: 'category_id_label', 
					label: 'Category'
				},
				// {
				// 	key: 'manufacturer_label', 
				// 	label: 'Manufacturer'
				// },
				{
					key: 'main_qty', 
					label: 'Main Qty'
				},
				{
					key: 'unit', 
					label: 'Unit'
				},
				{
					key: 'stock_limit', 
					label: 'Stock Limit'
				},
				{
					key: 'cost', 
					label: 'Cost'
				},
				{
					key: 'status_label', 
					label: 'Status'
				},
				{
					key: 'action',
					label: 'Action',
					_style: { width: '20px'}
				}
			],
			currentPageAll: 1,
			currentPageVehicle: 1,
			currentPageEquipment: 1,
			currentPage: 1,
			currentPageReplineshment: 1,
			currentPageInactive: 1,
			editMode: false,
			selectedRow: {},
			showCollapse: false,
			filter: {
				part_no: "",
	            part_identifier: "",
	            part_name: "",
	            category_id: "",
	            manufacturer: "",  
			},
			vendorList: {
				data: []
			},
			depotList: {
				data:[]
			},
			personnelTypeList: {
				data: []
			},

			vehicleList: {
				data: []
			},
			equipmentList: {
				data: []
			},
			originList: {
				data: []
			},
			customerList: {
				data: []
			},
			// equipmentCurrentLocationList: {
			// 	data: []
			// },
			moment
		}
	},
	name: 'Tables',
	components: { Datepicker, vSelect, Search},
	methods: {
		setCount(){
			if(this.current_tab == "All"){
				this.count = this.dataListAll.total
			}
			if(this.current_tab == "Replineshment"){
				this.count = this.dataListAllReplineshment.total
			} 
		},
		updatePageAll(data){
			this.currentPageAll = data;
			this.getDataAll();
		},
		updatePageReplineshment(data){
			this.currentPageReplineshment = data;
			this.getDataAllReplineshment();
		},
		updatePageVehicle(data){
			this.currentPageVehicle = data;
			this.getDataVehicle()
		},
		updatePageEquipment(data){
			this.currentPageEquipment = data;
			this.getDataEquipment();
		}, 

	    search(event){
	    	this.filter = event; 
	    	this.getDataAll();
	    	// this.getDataEquipment();
	    	// this.getDataVehicle();
	    },
  			updateModal(item){

	    	this.selectedRow = item;
	    	this.dataParams = item;
	    	// this.dataParams.incident_date = moment.unix(this.dataParams.incident_date).format('MMMM DD, YYYY')
	    	this.formModal = true;
	    	this.editMode = true;
			this.title = "Update Parts Quantity"

	    },
	    getDataAll(){ 

			this.isLoading=true;
	    	var category_id = this.filter.category_id;
	    	if(category_id == "all" || category_id == null){
	    		category_id = ""
	    	} 

	    	axios.get(config.api_path+'/partInventory?category_id='+category_id+
	    		'&part_no='+this.filter.part_no+
	    		'&part_identifier='+this.filter.part_identifier+
	    		'&part_name='+this.filter.part_name+
	    		'&manufacturer='+this.filter.manufacturer+ 
	    		'&page='+this.currentPageAll+'&limit=20')
	    	.then(response => {
	    		this.dataListAll = response.data; 
	    		this.dataListAll.data = this.dataListAll.data.map((value, index)=>{ 
	    			
	    			value.category_id_label = "N/A";
	    			if(value.category){
	    				value.category_id_label = value.category.setting_name;
	    			}

	    			value.manufacturer_label = "N/A";
	    			if(value.manufacturer != "" && value.manufacturer != null){
	    				value.manufacturer_label = value.manufacturer;
	    			}

	    			value.status_label = "-";
	    			if(value.status && value.status != ""){
	    				value.status_label = value.status;
	    			}

	    			return value;
	    		}); 
	    		this.setCount()
	    	})
			.catch(err => {
				console.error(err)
				this.isLoading = false;
			})
			.finally(fin => {
				console.log(fin)
				this.isLoading = false;	
			})
	    },

	    getDataAllReplineshment(){ 

			this.isLoading=true;
	    	var category_id = this.filter.category_id;
	    	if(category_id == "all" || category_id == null){
	    		category_id = ""
	    	} 

	    	axios.get(config.api_path+'/partInventory?category_id='+category_id+
	    		'&part_no='+this.filter.part_no+
	    		'&part_identifier='+this.filter.part_identifier+
	    		'&part_name='+this.filter.part_name+
	    		'&manufacturer='+this.filter.manufacturer+ 
	    		'&status=Needs replenishment.'+
	    		'&page='+this.currentPageReplineshment+'&limit=20')
	    	.then(response => {
	    		this.dataListAllReplineshment = response.data; 
	    		this.dataListAllReplineshment.data = this.dataListAllReplineshment.data.map((value, index)=>{ 
	    			
	    			value.category_id_label = "N/A";
	    			if(value.category){
	    				value.category_id_label = value.category.setting_name;
	    			}

	    			value.manufacturer_label = "N/A";
	    			if(value.manufacturer != "" && value.manufacturer != null){
	    				value.manufacturer_label = value.manufacturer;
	    			}

	    			value.status_label = "-";
	    			if(value.status && value.status != ""){
	    				value.status_label = value.status;
	    			}

	    			return value;
	    		}); 
	    		this.setCount()
	    	})
			.catch(err => {
				console.error(err)
				this.isLoading = false;
			})
			.finally(fin => {
				console.log(fin)
				this.isLoading = false;	
			})
	    },

		
	    updatePartsInventory(item){

	    	// this.selectedRow = item;
	    	// this.dataParams = item;
	    	// this.formModal = true;
	    	// this.editMode = true;
	    	this.$router.push('/main/update_parts_inventory/'+item.id)

	    },

	    viewPartsInventory(item){

	    	// this.selectedRow = item;
	    	// this.dataParams = item;
	    	// this.showViewModal = true; 
	    	this.$router.push('/main/view_parts_inventory/'+item.id)

	    },

	  

	    deleteRow(item){
	    	Swal.fire({
				icon:'warning',
				title: 'Are you sure you want to delete this part?', 
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonText: `Yes,delete it.`, 
			}).then((result) => { 
				if (result.isConfirmed) {
					axios.delete(config.api_path+'/partInventory/'+item.id) 
			    	.then(response => {
						this.getDataAll();
			    		Swal.fire({
							title: 'Success!',
							text: "Part successfully deleted",
							icon: 'success', 
						})
			    	}) 
				}
			})  
	    },

	    submit(){
	    	axios.put(config.api_path+'/partInventory/'+this.selectedRow.id+"?type=add_qty", {main_qty: this.dataParams.current_qty}) 
	    	.then(response => {
				this.getDataAll();
	    		Swal.fire({
					title: 'Success!',
					text: "Quantity successfully updated",
					icon: 'success', 
				})
				this.formModal = false;
	    	}) 
	    }
	   

 	},
 	watch: {
 		dataParams: {
 			handler(val, oldVal){
 				if(this.dataParams.class_type == 'vehicle'){
 					if(this.dataParams.vehicle_id != ""){
 						var selected_vehicle = this.vehicleList.data.filter((value, index)=>{
 							if(value.id == this.dataParams.vehicle_id){
 								this.dataParams.current_location_id = value.origin_id;
 								this.dataParams.type = value.vehicle_type.setting_name;
 							}
 						})
 					}
 				}
 				if(this.dataParams.class_type == 'equipment'){
 					if(this.dataParams.equipment_id != ""){
 						var selected_vehicle = this.equipmentList.data.filter((value, index)=>{
 							if(value.id == this.dataParams.equipment_id){
 								this.dataParams.current_location_id = value.origin_id;
 								this.dataParams.type = value.type;
 							}
 						})
 					}
 				}
 			},
 			deep: true
 		}
 	}
}
</script>
